var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('c-flex', {
    attrs: {
      "my": "10px",
      "align-items": "center",
      "justify": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "mt": "10px",
      "font-size": "16px",
      "font-weight": "700",
      "color": "#333"
    }
  }, [_vm._v(" Saran-saran ")]), _vm.isRoleNutritionist ? _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "border-radius": "50px"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$emit('edit');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "mr": "10px",
      "src": require('@/assets/icon-pencil.svg'),
      "alt": "icon"
    }
  }), _vm._v(" Edit ")], 1) : _vm._e()], 1), _c('c-text', {
    attrs: {
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s(_vm.saran.advice) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }