var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('c-flex', {
    attrs: {
      "my": "10px",
      "align-items": "center",
      "justify": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "mt": "10px",
      "font-size": "16px",
      "font-weight": "700",
      "color": "#333"
    }
  }, [_vm._v(" Kondisi tubuh ")]), _vm.isNutri ? _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "border-radius": "50px",
      "as": "router-link",
      "to": {
        name: 'nutri.body-composition-edit'
      }
    }
  }, [_c('c-image', {
    attrs: {
      "mr": "10px",
      "src": require('@/assets/icon-pencil.svg'),
      "alt": "icon"
    }
  }), _vm._v(" Edit ")], 1) : _vm._e()], 1), _c('table', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "10px"
    }
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Sekarang")]), _c('th', [_vm._v("Standard")]), _c('th', [_vm._v("Status")])])]), _c('tbody', [_vm.isEmpty ? _c('tr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "100%",
      "bg": "none"
    }
  }, [_c('td', {
    staticClass: "no-background",
    attrs: {
      "colspan": "3",
      "bg": "none"
    }
  }, [_c('EmptyState', {
    attrs: {
      "title": "Kondisi tubuh belum terisi"
    }
  })], 1)]) : _vm._l(_vm.kondisiTubuhMapped, function (data) {
    return [_c('tr', {
      key: "title-".concat(data.title)
    }, [_c('td'), _c('td', [_vm._v(_vm._s(data.title))]), _c('td', [_vm._v(" ")])]), _c('tr', {
      key: "point-".concat(data.title)
    }, [_c('td', [_vm._v(_vm._s(data.pointSekarang))]), _c('td', [_vm._v(_vm._s(data.pointStandard))]), _c('td', [_vm._v(_vm._s(_vm._f("toConclusion")(data.conclusion)))])])];
  })], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }