<template>
  <c-flex flex-dir="column">
    <c-box
      bg="linear-gradient(90deg, #F00 0%, #35A83A 35%, #35a83a 60%, #FFE600 70%, #ff0000 90%, #9D0000 100%)"
      w="100%"
      h="15px"
      pos="relative"
      border-radius="25px"
    >
      <c-box
        pos="absolute"
        top="50%"
        transform="translateY(-50%)"
        w="2px"
        h="20px"
        bg="white"
        left="26%"
      />
      <c-box
        pos="absolute"
        top="50%"
        transform="translateY(-50%)"
        w="2px"
        h="20px"
        bg="white"
        left="65%"
      />
      <c-box
        pos="absolute"
        top="50%"
        transform="translate(-50%, -50%)"
        :left="`${value}%`"
        h="20px"
        w="20px"
        border-radius="25px"
        bg="white"
        box-shadow="0px 0px 5px rgba(0, 0, 0, 0.25)"
      />
    </c-box>
    <c-flex
      justify-content="space-between"
      mt="10px"
    >
      <c-text
        font-size="12px"
        font-weight="400"
        color="#555"
      >
        Kurang Gizi
      </c-text>
      <c-text
        font-size="12px"
        font-weight="400"
        color="#555"
      >
        Normal
      </c-text>
      <c-text
        font-size="12px"
        font-weight="400"
        color="#555"
      >
        Obesitas
      </c-text>
    </c-flex>
  </c-flex>
</template>

<script>
export default {
  name: 'ProgressComp',
  props: ['value'],
}
</script>
