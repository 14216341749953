var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        gap: '8px'
      },
      expression: "{ gap: '8px' }"
    }],
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "flex-basis": "66px",
      "font-size": "12px",
      "font-weight": "400",
      "color": "#555"
    }
  }, [_vm._v(" Sekarang ")]), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "border-left": "1px solid #000",
      "w": "".concat(_vm.pointSekarangWidth, "px"),
      "h": "30px",
      "py": "3px"
    }
  }, [_c('c-box', {
    attrs: {
      "h": "20px",
      "w": "100%",
      "bg": "#F4CC46",
      "border-top-right-radius": "4px",
      "border-bottom-right-radius": "4px"
    }
  })], 1), _c('c-text', {
    attrs: {
      "ml": "5px",
      "font-size": "12px",
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("toFixed")(_vm.pointSekarang)) + " " + _vm._s(_vm.satuan) + " ")])], 1), _c('c-flex', {
    attrs: {
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "flex-basis": "66px",
      "font-size": "12px",
      "font-weight": "400",
      "color": "#555"
    }
  }, [_vm._v(" Kebutuhan ")]), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "border-left": "1px solid #000",
      "w": "".concat(_vm.pointKebutuhanWidth, "px"),
      "h": "30px"
    }
  }, [_c('c-box', {
    attrs: {
      "h": "20px",
      "w": "100%",
      "bg": "#00A68C",
      "border-top-right-radius": "4px",
      "border-bottom-right-radius": "4px"
    }
  })], 1), _c('c-text', {
    attrs: {
      "ml": "5px",
      "font-size": "12px",
      "font-weight": "400",
      "color": "#555"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("toFixed")(_vm.pointKebutuhan)) + " " + _vm._s(_vm.satuan) + " ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "10px",
      "bg": "#F2F2F2",
      "p": "8px",
      "border-radius": "4px"
    }
  }, [_c('c-image', {
    attrs: {
      "h": "18px",
      "w": "18px",
      "src": require('@/assets/icon-pentung-grey.svg'),
      "alt": "icon alert"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "12px",
      "font-weight": "400",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(_vm.warningText) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }