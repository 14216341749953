var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    attrs: {
      "mt": "15px",
      "overflow-x": "auto",
      "align-items": "center",
      "justify-content": "space-around"
    }
  }, _vm._l(_vm.weeksRange, function (week, index) {
    return _c('BadgeWeek', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      key: week,
      attrs: {
        "selected": week === +(_vm.$route.query.day || 1),
        "week": week,
        "ml": index === 0 ? '' : '10px'
      },
      on: {
        "click": function click($event) {
          return _vm.$router.replace({
            query: Object.assign({}, _vm.$route.query, {
              day: week
            })
          });
        }
      }
    });
  }), 1), _c('c-flex', {
    attrs: {
      "mt": "15px",
      "flex-dir": "column"
    }
  }, [_c('card-menu', {
    attrs: {
      "menus": _vm._menus
    },
    on: {
      "select-menu": function selectMenu($event) {
        _vm.selectedRencanaMakan = $event;
      }
    }
  })], 1), _vm.selectedRencanaMakan ? _c('Portal', {
    attrs: {
      "to": "main"
    }
  }, [_c('c-box', {
    attrs: {
      "top": "0",
      "left": "0",
      "height": "100vh",
      "width": "100vw",
      "position": "fixed",
      "z-index": "1001"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "absolute",
      "top": "0",
      "left": "0",
      "height": "100vh",
      "width": "100vw",
      "bg": "#333c"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        _vm.selectedRencanaMakan = null;
      }
    }
  }), _c('c-flex', {
    attrs: {
      "position": "fixed",
      "top": "50%",
      "left": "50%",
      "transform": "translate(-50%, -50%)",
      "bg": "white",
      "rounded": "md",
      "flex-dir": "column",
      "min-w": "700px",
      "max-h": "600px"
    }
  }, [_c('c-flex', {
    attrs: {
      "p": "20px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "h": "24px",
      "w": "24px",
      "min-w": "0",
      "p": "0",
      "m": "0"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        $event.stopPropagation();
        _vm.selectedRencanaMakan = null;
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "close",
      "size": "14px",
      "color": "#333"
    }
  })], 1), _c('c-text', {
    attrs: {
      "ml": "15px"
    }
  }, [_vm._v(" Detail menu ")])], 1), _c('c-flex', {
    attrs: {
      "overflow-y": "auto",
      "overflow-x": "hidden",
      "px": "35px",
      "py": "10px",
      "grid-gap": "35px"
    }
  }, [_vm._l(_vm.selectedRencanaMakan.meals, function (meal) {
    return [_c('c-box', {
      key: meal.id,
      attrs: {
        "w": "72px",
        "h": "auto",
        "flex-dir": "column",
        "align-items": "center",
        "justify-content": "flex-start",
        "font-weight": "initial"
      }
    }, [_c('c-image', {
      attrs: {
        "src": meal.mealIngredientNameGlossary.photoUrl,
        "alt": meal.mealIngredientNameGlossary.ingredientName,
        "h": "72px",
        "w": "72px",
        "object-fit": "cover",
        "box-shadow": "0px 0px 8px 5px rgba(0, 0, 0, 0.12)",
        "border-radius": "8px"
      }
    }), _c('c-text', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          'word-wrap': 'anywhere'
        },
        expression: "{\n                  'word-wrap': 'anywhere',\n                }"
      }],
      attrs: {
        "m": "0",
        "mt": "10px",
        "font-size": "12px",
        "text-align": "center",
        "color": "#333333",
        "max-w": "100%",
        "white-space": "break-spaces",
        "flex": "1"
      }
    }, [_vm._v(" " + _vm._s(meal.mealIngredientNameGlossary.ingredientName) + " ")]), _c('c-text', {
      attrs: {
        "p": "0",
        "mt": "2px",
        "font-size": "12px",
        "text-align": "center",
        "color": "#888888"
      }
    }, [_vm._v(" " + _vm._s(meal.dose) + " " + _vm._s(meal.doseUnit) + " ")])], 1)];
  })], 2), _c('c-button', {
    attrs: {
      "type": "button",
      "variant": "outline",
      "variant-color": "primary",
      "m": "20px",
      "rounded": "full",
      "as": "router-link",
      "to": {
        name: 'glossaries'
      }
    }
  }, [_vm._v(" Lihat glossary foto makan ")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }