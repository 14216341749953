var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('c-box', {
    attrs: {
      "bg": "linear-gradient(90deg, #F00 0%, #35A83A 35%, #35a83a 60%, #FFE600 70%, #ff0000 90%, #9D0000 100%)",
      "w": "100%",
      "h": "15px",
      "pos": "relative",
      "border-radius": "25px"
    }
  }, [_c('c-box', {
    attrs: {
      "pos": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "w": "2px",
      "h": "20px",
      "bg": "white",
      "left": "26%"
    }
  }), _c('c-box', {
    attrs: {
      "pos": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "w": "2px",
      "h": "20px",
      "bg": "white",
      "left": "65%"
    }
  }), _c('c-box', {
    attrs: {
      "pos": "absolute",
      "top": "50%",
      "transform": "translate(-50%, -50%)",
      "left": "".concat(_vm.value, "%"),
      "h": "20px",
      "w": "20px",
      "border-radius": "25px",
      "bg": "white",
      "box-shadow": "0px 0px 5px rgba(0, 0, 0, 0.25)"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "mt": "10px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "12px",
      "font-weight": "400",
      "color": "#555"
    }
  }, [_vm._v(" Kurang Gizi ")]), _c('c-text', {
    attrs: {
      "font-size": "12px",
      "font-weight": "400",
      "color": "#555"
    }
  }, [_vm._v(" Normal ")]), _c('c-text', {
    attrs: {
      "font-size": "12px",
      "font-weight": "400",
      "color": "#555"
    }
  }, [_vm._v(" Obesitas ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }