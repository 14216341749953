var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full"
    }
  }, [_c('Breadcrumbs', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "full"
    }
  }), _c('c-box', {
    attrs: {
      "mt": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "text-transform": "uppercase"
    }
  }, [_vm._v(" Detail Program ")]), _c('c-text', {
    attrs: {
      "font-size": "24px",
      "font-weight": "700"
    }
  }, [_vm._v(" Meal Plan ")])], 1), _c('c-box', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-tabs', {
    attrs: {
      "is-fitted": "",
      "is-manual": "",
      "variant": "enclosed",
      "border-color": "primary.400",
      "variant-color": "primary",
      "index": _vm.activeTabIndex,
      "default-index": _vm.activeTabIndex
    },
    on: {
      "change": _vm.handleTabChange
    }
  }, [_c('c-tab-list', {
    attrs: {
      "bg": "#f2f2f2",
      "rounded-top": "lg"
    }
  }, _vm._l(['Rencana Makan', 'Rekomendasi Menu', 'Profile Gizi'], function (label, index) {
    return _c('c-tab', {
      key: label,
      attrs: {
        "rounded-top": "lg",
        "font-size": "18px",
        "font-weight": "700",
        "bg": index === _vm.activeTabIndex ? '#fff' : 'none',
        "color": index === _vm.activeTabIndex ? 'primary.400' : '#c4c4c4',
        "outline-color": "none"
      }
    }, [_vm._v(" " + _vm._s(label) + " ")]);
  }), 1), _c('c-tab-panels', {
    attrs: {
      "border": "1px",
      "border-color": "primary.400",
      "rounded-bottom": "lg",
      "p": "20px"
    }
  }, [_c('c-tab-panel', [_c('RencanaMakan')], 1), _c('c-tab-panel', [_c('RekomendasiMenu')], 1), _c('c-tab-panel', [_c('ProfileGizi')], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }